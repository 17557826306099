import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-save-reset-buttons',
    templateUrl: './save-reset-buttons.component.html',
    styleUrls: ['./save-reset-buttons.component.css']
})
export class SaveResetButtonsComponent {

    @Output('onSaveClick')
    onSaveEvent: EventEmitter<null> = new EventEmitter<null>();

    @Output('onResetClick')
    onResetEvent: EventEmitter<null> = new EventEmitter<null>();

    @Input('formButtonsDisabled')
    formButtonsDisabled: boolean;

    constructor() {
    }

    onSave(): void {
        this.onSaveEvent.emit(null);
    }

    onReset(): void {
        this.onResetEvent.emit(null);
    }

}
