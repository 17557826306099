import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeModule} from './home/home.module';
import {MetadataConfigsModule} from './metadata/metadata-configs.module';
import {LoginCallbackComponent} from './login-callback/login-callback.component';
import {AppGuard} from './core/guard/app-guard.service';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(() => HomeModule),
        canActivate: [AppGuard]
    },
    {
        path: 'configs',
        loadChildren: () => import('./metadata/metadata-configs.module').then(() => MetadataConfigsModule),
        canActivate: [AppGuard]
    },
    {
        path: 'auth-callback',
        component: LoginCallbackComponent
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
