import {TabIdentifier} from '../enums/tab-identifier';
import {IntegrationIdentifier} from '../enums/integration-identifier';

export class DisplayIdentifiers {

    tabId: TabIdentifier;
    integrationIds: IntegrationIdentifier[];

    constructor() {
    }

}