import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {SharedModule} from '../../shared/shared.module';
import {NgForOf, NgIf} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {Setting} from '../../core/models/setting';
import {UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export interface ArcFees {
    rates: ArcFeeEntry[];
}

export interface ArcFeeOverride {
    recordTypeName: string;
    arcFee: number;
}

export interface ArcFeeEntry {
    startDate?: string;
    arcFee: number;
    affidavitFee: number;
    arcFeeOverrides?: ArcFeeOverride[];
}

@Component({
    selector: 'app-arc-fee-editor',
    templateUrl: './arc-fee-editor.component.html',
    styleUrls: ['./arc-fee-editor.component.css'],
    standalone: true,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        SharedModule,
        NgForOf,
        MatExpansionModule,
        NgIf
    ]
})
export class ArcFeeEditorComponent implements OnInit {

    @Input('setting')
    setting: Setting;

    @Input('settingsArrayFormGroup')
    settingsArrayFormGroup: UntypedFormGroup;

    arcFees: ArcFees;

    protected arcFeeError: boolean;

    private saveArcFeesSubject = new Subject<void>();

    ngOnInit(): void {
        this.arcFeeError = false;
        // Copy setting.value to arcFeeData when the component initializes
        this.updateArcFeesFromSetting();
        this.saveArcFeesSubject.pipe(debounceTime(1000)).subscribe(() => {
            this.saveArcFeesInternal(); // Call the actual save function
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['settingsArrayFormGroup'] && !changes['settingsArrayFormGroup'].firstChange) {
            this.updateArcFeesFromSetting();
        }
    }

    private updateArcFeesFromSetting() {
        this.arcFees = JSON.parse(this.setting.value);
    }

    addEntry() {
        this.arcFees.rates.push({arcFee: null, affidavitFee: null});
        this.saveArcFees();
    }

    removeEntry(index: number) {
        this.arcFees.rates.splice(index, 1);
        this.saveArcFees();
    }

    addOverride(entry: ArcFeeEntry) {
        if (!entry.arcFeeOverrides) {
            entry.arcFeeOverrides = [];
        }
        entry.arcFeeOverrides.push({recordTypeName: null, arcFee: null});
        this.saveArcFees();
    }

    removeOverride(entry: ArcFeeEntry, index: number) {
        entry.arcFeeOverrides?.splice(index, 1);
        this.saveArcFees();
    }


    saveArcFees() {
        this.saveArcFeesSubject.next();
    }

    saveArcFeesInternal() {
        const control = this.settingsArrayFormGroup.get(this.setting.name);
        const jsonValid = this.isValidJson();
        this.arcFeeError = !jsonValid;
        if (jsonValid) {
            control.setErrors(null)
            control.setValue(this.getJsonData(false))
        } else {
            control.setErrors({invalid: true})
        }
    }

    isValidJson(): boolean {
        if (!this.arcFees.rates) {
            return false;
        }
        if (this.arcFees.rates.length === 0) {
            return false;
        }
        return this.arcFees.rates.every((entry, i) => {
            if (entry.affidavitFee == null) {
                return false;
            }
            if (entry.arcFee == null) {
                return false;
            }
            if (i !== 0 && entry.startDate === null) {
                return false;
            }
            if (entry.arcFeeOverrides) {
                return entry.arcFeeOverrides.every(override => {
                    if (override.recordTypeName == null) {
                        return false;
                    }
                    return override.arcFee != null;
                });
            }
            return true;
        });
    }

    getJsonData(format: boolean) {
        const dateReviver = (key: string, value: any) => {
            if (key === 'startDate' && typeof value === 'string') {
                const date = new Date(value);
                const y = date.getFullYear();
                const m = this.padStart(String(date.getMonth() + 1), 2, '0');
                const d = this.padStart(String(date.getDate()), 2, '0');
                return y + '-' + m + '-' + d;
            }
            return value;
        };
        return JSON.stringify(this.arcFees, dateReviver, format ? 2 : null);
    }

    padStart(str: string, targetLength: number, padString: string): string {
        while (str.length < targetLength) {
            str = padString + str;
        }
        return str;
    }

    validateInput(event: KeyboardEvent): void {
        if (event.key === '.' || event.key === '-' || event.key === '+' || event.key === 'e') {
            event.preventDefault();
        }
    }
}
