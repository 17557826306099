<ng-container [formGroup]="settingsArrayFormGroup" [ngSwitch]="setting.dataType" style="background-color: antiquewhite">
    <mat-icon (click)="openDialog(setting)"
              class="vertical-center pointer info-icon"
              color="primary"
              matPrefix>info
    </mat-icon>

    <button *ngSwitchCase="SettingDataType.BUTTON"
            color="primary"
            mat-raised-button>{{setting.name}}</button>

    <mat-form-field *ngSwitchCase="SettingDataType.TEXT_AREA" appearance="outline" class="mat-input-width test">
        <mat-label>{{setting.name}}</mat-label>
        <textarea [formControlName]="setting.name" matInput autosize
                  [pattern]="setting.pattern?setting.pattern:null"
                  [minRows]="setting.minRows?setting.minRows:5">{{setting.value}}</textarea>
        <mat-error *ngIf="setting.invalid">{{setting.validationMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.INPUT" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input [formControlName]="setting.name" matInput placeholder="Value"
               [pattern]="setting.pattern?setting.pattern:null"
               [required]="setting.required"
               [maxlength]="setting.maxLength?setting.maxLength.toString():null"
               [minlength]="setting.minLength?setting.minLength.toString():null"/>
        <mat-error *ngIf="setting.invalid">{{setting.validationMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.TEXT_ARRAY" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input (blur)="trimTextArrayInput($event, setting)" [formControlName]="setting.name" matInput
               placeholder="Value">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.PASSWORD" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input [formControlName]="setting.name" class=" mat-input-wrapper" matInput placeholder="Value"
               [type]="setting.showPassword ? 'text' : 'password'"
               [required]="setting.required"
               autocomplete="new-password"
               value="{{setting.value}}">
        <mat-error *ngIf="setting.invalid">{{setting.validationMessage}}</mat-error>
        <mat-icon matSuffix (click)="togglePasswordVisibility(setting)">
            {{setting.showPassword ? 'visibility_off' : 'visibility'}}
        </mat-icon>
    </mat-form-field>

    <div *ngSwitchCase="SettingDataType.SELECT" class="mat-input-width-inline">
        <mat-form-field appearance="outline">
            <mat-label>{{setting.name}}</mat-label>
            <mat-select [formControlName]="setting.name">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of setting.displayData.options" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-slide-toggle *ngSwitchCase="SettingDataType.TOGGLE" [checked]="setting.value"
                      [formControlName]="setting.name"
                      class="mat-input-width">
        {{setting.name}}
    </mat-slide-toggle>
</ng-container>
