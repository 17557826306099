import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Setting} from '../../core/models/setting';
import {IntegrationIdentifier} from '../../core/enums/integration-identifier';
import {TabIdentifier} from '../../core/enums/tab-identifier';
import {DisplayData} from '../../core/models/display-data';
import {SettingMetadataService} from '../../core/services/setting-metadata/setting-metadata.service';
import {DisplayIdentifiers} from '../../core/models/display-Identifiers';
import {SettingMetadata} from '../../core/models/setting-metadata';
import {SettingDataType} from 'app/core/enums/setting-data-type';

@Component({
    selector: 'app-metadata-edit-popup',
    templateUrl: './metadata-popup.component.html',
    styleUrls: ['./metadata-popup.component.css']
})
export class MetadataPopupComponent implements OnInit {
    form: UntypedFormGroup;
    submitted = false;
    SettingDataType = SettingDataType;
    settingDataTypes = SettingDataType.getValues();
    integrations = IntegrationIdentifier.getKeys();
    IntegrationIdentifier = IntegrationIdentifier;
    tabs = TabIdentifier.getValues();
    dataSaved: EventEmitter<void> = new EventEmitter<void>();

    constructor(private dialogRef: MatDialogRef<MetadataPopupComponent>,
                private formBuilder: UntypedFormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: Setting,
                private settingMetadataService: SettingMetadataService) {
        this.data = <Setting>(data ? data : {});
        this.ngOnInit();
    }

    ngOnInit(): void {
        const controlsConfig = {};

        controlsConfig['name'] = new UntypedFormControl(this.data.name, Validators.required);
        controlsConfig['settingIdentifier'] = new UntypedFormControl(this.data.identifier, Validators.required);
        controlsConfig['active'] = new UntypedFormControl(this.data.active, Validators.required);
        controlsConfig['dataType'] = new UntypedFormControl(this.data.dataType, Validators.required);
        controlsConfig['tooltipDescription'] = new UntypedFormControl(this.data.tooltipDescription, Validators.required);
        controlsConfig['tooltipLocation'] = new UntypedFormControl(this.data.tooltipLocation);

        const displayData = <DisplayData>(this.data.displayData ? this.data.displayData : {});
        const order = displayData.order;
        const options = displayData.options;
        const integrations = displayData.displayIdentifiers ? displayData.displayIdentifiers.integrationIds : [];
        const tab = displayData.displayIdentifiers ? displayData.displayIdentifiers.tabId : null;

        controlsConfig['displayData_order'] = new UntypedFormControl(order, Validators.required);
        controlsConfig['displayData_options'] = new UntypedFormControl(options);
        controlsConfig['displayData_integrations'] = new UntypedFormControl(integrations);
        controlsConfig['displayData_tab'] = new UntypedFormControl(tab, Validators.required);

        this.form = this.formBuilder.group(controlsConfig);
        this.form.controls.displayData_tab.valueChanges
            .subscribe(() => {
                this.onDisplayDataTabChange();
            });
    }

    onDisplayDataTabChange() {
        const integrationDataFormControl = this.form.controls.displayData_integrations;
        if (this.form.controls.displayData_tab.value === TabIdentifier.INTEGRATION) {
            integrationDataFormControl.setValidators(Validators.required);
        } else {
            integrationDataFormControl.setValidators(null);
        }
        integrationDataFormControl.updateValueAndValidity();
    }

    onSubmit() {
        this.submitted = false;

        if (this.form.valid) {

            const displayIdentifiers: DisplayIdentifiers = new DisplayIdentifiers();
            displayIdentifiers.integrationIds = this.form.controls.displayData_integrations.value;
            displayIdentifiers.tabId = this.form.controls.displayData_tab.value;

            let options = this.form.controls.displayData_options.value;
            options = options && (typeof options === 'string')
                ? options.split(',').map(s => s.trim())
                : options;

            const displayData: DisplayData = new DisplayData();

            displayData.order = +this.form.controls.displayData_order.value;
            displayData.displayIdentifiers = displayIdentifiers;
            displayData.options = options;

            const settingIdentifier: string = this.form.controls.settingIdentifier.value;

            const settingMeta: SettingMetadata = new SettingMetadata();

            settingMeta.name = this.form.controls.name.value;
            settingMeta.settingIdentifier = settingIdentifier.toLowerCase();
            settingMeta.active = this.form.controls.active.value;
            settingMeta.dataType = this.form.controls.dataType.value;
            settingMeta.displayData = displayData;
            settingMeta.tooltipDescription = this.form.controls.tooltipDescription.value;
            settingMeta.tooltipLocation = this.form.controls.tooltipLocation.value;
            this.settingMetadataService.saveSettingMetadata(settingMeta)
                .subscribe(() => {
                        this.dataSaved.emit();
                        this.dialogRef.close();
                    }
                );
        } else {
            console.log('Form is not valid')
        }
    }

    inputToLowerCase(event, formControlName) {
        const inputValue = event.target.value.toLowerCase()
            .replace(/\s/g, '_')
            .trim();
        this.form.controls[formControlName].setValue(inputValue);
    }

}
