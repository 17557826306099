<mat-toolbar color="primary">
    <span class="title">Legal Firm Settings</span>

    <span style="flex: 1 1 auto;"></span>

    <button (click)="logout()" id="logoutButton"
            mat-raised-button>Logout
    </button>

</mat-toolbar>
