import {SettingDTO} from '../dto/setting-dto';

export class FirmDetails {

    legalFirmId: number;
    companyName: string;

    constructor(settingDto: SettingDTO) {
        this.companyName = settingDto.setting_value;
        this.legalFirmId = settingDto.firm_id;
    }
}
