export enum SettingDataType {
    SELECT = 'select',
    TOGGLE = 'toggle',
    INPUT = 'input',
    BUTTON = 'button',
    PASSWORD = 'password',
    TEXT_AREA = 'textArea',
    TEXT_ARRAY = 'textArray',
    ARC_FEE_RATES = 'arcFeeRates'
}

export namespace SettingDataType {
    export function valueOf(dataTypeString: string): SettingDataType {
        const find = Object.keys(SettingDataType)
            .map(v => SettingDataType[v])
            .find(type => type === dataTypeString);

        if (find) {
            return find;
        } else {
            throw new Error('SettingDataType not found');
        }
    }

    export function getValues(): string[] {
        return Object.keys(SettingDataType)
            .filter(key => typeof SettingDataType[key] === 'string')
            .map(key => SettingDataType[key]);
    }
}
