<div mat-dialog-content>
    <h1 mat-dialog-title>New Legal Firm</h1>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="popup-form">
        <mat-form-field class="popup-field-full-width">
            <mat-label>Legal firm id:</mat-label>
            <input
                    formControlName="legalFirmId"
                    id="legalFirmId"
                    matInput
                    placeholder="Legal firm id"
                    type="number">
        </mat-form-field>
        <mat-error *ngIf="submitted && form.controls.legalFirmId.invalid" id="legalFirmId-error">
            Please enter legal firm id
        </mat-error>
        <mat-form-field class="popup-field-full-width">
            <mat-label>Company name:</mat-label>
            <input formControlName="companyName"
                   id="companyName"
                   matInput
                   placeholder="Company name">
        </mat-form-field>
        <mat-error *ngIf="submitted && form.controls.companyName.invalid" id="companyName-error">
            Please enter company name
        </mat-error>
        <button color="primary"
                id="save"
                mat-raised-button type="submit">
            Save
        </button>
        <button [mat-dialog-close]="this" id="cancel" mat-button>Cancel</button>
    </form>
</div>
