import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MetadataConfigsRoutingModule} from './metadata-configs-routing.module';
import {SharedModule} from '../shared/shared.module';
import {MetadataPopupComponent} from './metadata-popup/metadata-popup.component';
import {AppMaterialModule} from '../app.material.module';
import {MetadataConfigsComponent} from './metadata-configs/metadata-configs.component';

@NgModule({
    declarations: [MetadataConfigsComponent, MetadataPopupComponent],
    imports: [
        SharedModule,
        MetadataConfigsRoutingModule,
        CommonModule,
        AppMaterialModule
    ]
})
export class MetadataConfigsModule {

}
