export enum TabIdentifier {
    LEGAL_FIRM_INFO = 'Legal Firm Info',
    ARC_INFORMATION = 'ARC Information',
    SETTINGS = 'Settings',
    ACCOUNTING = 'Accounting',
    INTEGRATION = 'Integration',
    PORTAL = 'Portal'
}

export namespace TabIdentifier {
    export function getValues(): string[] {
        return Object.keys(TabIdentifier)
            .filter(key => typeof TabIdentifier[key] === 'string')
            .map(key => TabIdentifier[key]);
    }
}

