import {Pipe, PipeTransform} from '@angular/core';
import {FirmDetails} from '../models/firm-details';

@Pipe({
    name: 'searchFirmDetailsFilterPipe'
})
export class SearchFirmDetailsFilterPipe implements PipeTransform {

    transform(arr: FirmDetails[], searchText: any): FirmDetails[] {

        if (!arr) {
            return [];
        }
        if (!searchText) {
            return arr;
        }
        searchText = searchText.toLowerCase();
        return arr.filter((it: FirmDetails) => {
            return it.companyName.toLowerCase().includes(searchText) || it.legalFirmId == searchText
        });

    }

}
