import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {IntegrationStatus} from "../../enums/integration-status";
import {HttpWrapperService} from "../http/http-wrapper.service";
import {map} from "rxjs/operators";
import {LegalFirmService} from "../legal-firm/legal-firm.service";

@Injectable({
    providedIn: 'root'
})
export class IntegrationManagerService {

    constructor(private httpWrapperService: HttpWrapperService,
                private legalFirmSettings: LegalFirmService) {
    }

    deployStop(): Observable<IntegrationStatus> {
        return this.sendState(IntegrationState.DEPLOY_STOP);
    }

    deployStart(): Observable<IntegrationStatus> {
        return this.sendState(IntegrationState.DEPLOY_START);
    }

    undeploy(): Observable<IntegrationStatus> {
        return this.sendState(IntegrationState.UNDEPLOY);
    }

    sendState(state: IntegrationState): Observable<IntegrationStatus> {
        const body = {
            firmId: this.legalFirmSettings.legalFirmId,
            state
        };
        return this.httpWrapperService
            .put<IntegrationStatus>("integration", body)
            .pipe(map(() => IntegrationStatus.IN_PROGRESS));
    }

}

export enum IntegrationState {
    DEPLOY_STOP = 'DEPLOY_STOP',
    DEPLOY_START = 'DEPLOY_START',
    UNDEPLOY = 'UNDEPLOY'
}
