<div class="container">
    <div *ngFor="let entry of arcFees.rates; let i = index">
        <mat-card appearance="outlined">
            <mat-card-content>
                <div mat-line>
                    <mat-form-field appearance="outline">
                        <mat-label>Start Date <span *ngIf="i === 0">(optional)</span></mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="entry.startDate"
                               (ngModelChange)="saveArcFees()" [required]="i !== 0">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Arc Fee (cents)</mat-label>
                        <input matInput type="number" [(ngModel)]="entry.arcFee" (ngModelChange)="saveArcFees()"
                               [required]="true" (keydown)="validateInput($event)">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Affidavit Fee (cents)</mat-label>
                        <input matInput type="number" [(ngModel)]="entry.affidavitFee" (ngModelChange)="saveArcFees()"
                               [required]="true" (keydown)="validateInput($event)">
                    </mat-form-field>
                </div>
                <mat-label>Overrides:</mat-label>
                <mat-card appearance="outlined">
                    <mat-card-content>
                        <div mat-line>
                            <div *ngFor="let override of entry.arcFeeOverrides; let j = index">
                                <div mat-line>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Record Type</mat-label>
                                        <input matInput [(ngModel)]="override.recordTypeName"
                                               (ngModelChange)="saveArcFees()" [required]="true">
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Arc Fee Override (cents)</mat-label>
                                        <input matInput type="number" [(ngModel)]="override.arcFee"
                                               (ngModelChange)="saveArcFees()" [required]="true"
                                               (keydown)="validateInput($event)">
                                    </mat-form-field>

                                    <button mat-button color="warn" (click)="removeOverride(entry, j)">
                                        Delete Override
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-button (click)="addOverride(entry)">Add Override</button>
                    </mat-card-actions>
                </mat-card>
                <mat-card-actions align="end">
                    <button mat-button color="warn" (click)="removeEntry(i)">Delete Entry</button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    </div>
    <div style="margin: 20px">
        <button mat-raised-button color="primary" (click)="addEntry()">Add ARC Fee Entry</button>
        <mat-error *ngIf="arcFeeError">Invalid ARC Fees format</mat-error>
    </div>

    <mat-expansion-panel expanded="false">
        <mat-expansion-panel-header>
            <mat-panel-title>Result ARC Fee Rates JSON:</mat-panel-title>
        </mat-expansion-panel-header>
        <pre>{{ getJsonData(true) }}</pre>
    </mat-expansion-panel>
</div>