import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IntegrationStatus} from "../../../core/enums/integration-status";
import {IntegrationManagerService} from "../../../core/services/integration-manager/integration-manager.service";
import {catchError} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ToasterService} from "../../../core/services/toastr/toaster.service";

@Component({
    selector: 'app-status-popup',
    templateUrl: './status-popup.component.html',
    styleUrls: ['./status-popup.component.css']
})
export class StatusPopupComponent implements OnInit {

    newStatusState = new EventEmitter<IntegrationStatus>();
    status: IntegrationStatus;
    IntegrationStatus = IntegrationStatus;
    updateForbidden = false;
    firmIds: number[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: IntegrationStatus,
                private integrationManager: IntegrationManagerService,
                private dialogRef: MatDialogRef<StatusPopupComponent>,
                private toasterService: ToasterService) {
        this.status = data;
    }

    ngOnInit(): void {
    }

    onDeployButtonClick(): void {
        this.integrationManager.deployStop()
            .pipe(catchError((err) => this.showUpdateForbidden(err)))
            .subscribe(newStatus => this.setNewStatus(newStatus));

    }

    onDeployStartButtonClick(): void {
        this.integrationManager.deployStart()
            .pipe(catchError((err) => this.showUpdateForbidden(err)))
            .subscribe(newStatus => this.setNewStatus(newStatus));
    }

    onUndeployButtonClick(): void {
        this.integrationManager.undeploy()
            .pipe(catchError((err) => this.showUpdateForbidden(err)))
            .subscribe(newStatus => this.setNewStatus(newStatus));
    }

    setNewStatus(newStatus: IntegrationStatus): void {
        this.newStatusState.emit(newStatus);
        this.dialogRef.close();
        this.toasterService.success("SUCCESS");
    }

    showUpdateForbidden(err: HttpErrorResponse): Observable<never> {
        if (err.status === 409) {
            this.updateForbidden = true;
            this.firmIds = (err.error.details as UpdateNotAllowedResponse).firmIdsInProgress;
            return of<never>();
        } else return throwError(err);
    }

}

interface UpdateNotAllowedResponse {
    firmIdsInProgress: number[];
}
