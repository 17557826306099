<div>
    <h1 mat-dialog-title>Setting metadata</h1>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="popup-form">
        <div mat-dialog-content>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Name:</mat-label>
                <input formControlName="name"
                       matInput
                       placeholder="Name">
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.name.invalid" id="name-error">
                Please enter legal firm id
            </mat-error>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Setting identifier:</mat-label>
                <input formControlName="settingIdentifier"
                       matInput
                       (input)="inputToLowerCase($event, 'settingIdentifier')"
                       placeholder="Setting Identifier">
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.settingIdentifier.invalid" id="settingIdentifier-error">
                Please enter setting identifier
            </mat-error>

            <mat-slide-toggle class="slide-toggle-style" formControlName="active" ngDefaultControl>Active
            </mat-slide-toggle>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Data type:</mat-label>
                <mat-select [formControlName]="'dataType'">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let type of settingDataTypes" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.dataType.invalid" id="dataType-error">
                Please enter data type
            </mat-error>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Display data (Order):</mat-label>
                <input formControlName="displayData_order"
                       matInput
                       placeholder="Order">
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.displayData_order.invalid" id="displayData-order-error">
                Please enter display data order
            </mat-error>

            <div *ngIf="SettingDataType.SELECT === form.controls.dataType.value">
                <mat-form-field class="popup-field-full-width">
                    <mat-label>Display data (Options):</mat-label>
                    <input formControlName="displayData_options"
                           matInput
                           placeholder="Options">
                </mat-form-field>
                <mat-error *ngIf="submitted && form.controls.displayData.invalid" id="displayData-options-error">
                    Please enter display data options
                </mat-error>
            </div>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Display data (Integrations):</mat-label>
                <mat-select formControlName="displayData_integrations" multiple>
                    <mat-option *ngFor="let integration of integrations" [value]="integration">
                        {{IntegrationIdentifier[integration]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.displayData.invalid" id="displayData-integration-error">
                Please enter display data integration
            </mat-error>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Display data (Tab):</mat-label>
                <mat-select formControlName="displayData_tab">
                    <mat-option *ngFor="let tabId of tabs" [value]="tabId">
                        {{tabId}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.displayData.invalid" id="displayData-tab-error">
                Please enter display data tab
            </mat-error>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Tooltip Description:</mat-label>
                <textarea formControlName="tooltipDescription"
                          matInput
                          placeholder="Tooltip Description">
                </textarea>
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.tooltipDescription.invalid" id="tooltipDescription-error">
                Please enter tooltip description
            </mat-error>

            <mat-form-field class="popup-field-full-width">
                <mat-label>Tooltip Location:</mat-label>
                <textarea formControlName="tooltipLocation"
                          matInput
                          placeholder="Tooltip Location">
                </textarea>
            </mat-form-field>
            <mat-error *ngIf="submitted && form.controls.tooltipLocation.invalid" id="tooltipLocation-error">
                Please enter tooltip location
            </mat-error>

        </div>

        <div mat-dialog-actions>
            <button color="primary"
                    id="save-button"
                    mat-raised-button type="submit">
                Save
            </button>
            <button [mat-dialog-close]="this" id="cancel" mat-button>Cancel</button>
        </div>

    </form>
</div>
