import {Component, OnInit} from '@angular/core';
import {GlobalSpinnerService} from '../../core/services/global-spinner/global-spinner.service';

@Component({
    selector: 'app-global-spinner',
    templateUrl: './global-spinner.component.html',
    styleUrls: ['./global-spinner.component.css']
})
export class GlobalSpinnerComponent implements OnInit {
    isLoading = false;

    constructor(private globalSpinnerService: GlobalSpinnerService) {
    }

    ngOnInit(): void {
        this.globalSpinnerService.isLoading.subscribe((value) => this.isLoading = value)
    }
}
