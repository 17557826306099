export class SettingDTO {

    firm_id: number;
    setting_id: string;
    setting_value: string;

    constructor(firmId: number, settingId: string, settingValue: string) {
        this.firm_id = firmId;
        this.setting_id = settingId;
        this.setting_value = settingValue;
    }
}
