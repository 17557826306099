<div class="button-row align-to-right">
    <button (click)="onSave()"
            [disabled]="formButtonsDisabled"
            color="primary" mat-raised-button>Save
    </button>
    <button (click)="onReset()"
            [disabled]="formButtonsDisabled"
            mat-raised-button>Reset
    </button>
</div>
