export enum IntegrationStatus {
    STOPPED = 'Stopped',
    STARTED = 'Started',
    UNDEPLOYED = 'Undeployed',
    IN_PROGRESS = 'In progress',
    UPDATE_FAILED = 'Invalid state',
}

export namespace IntegrationStatus {
    export function valueOf(dataTypeString: string): IntegrationStatus {
        const find = Object.keys(IntegrationStatus)
            .map(v => IntegrationStatus[v])
            .find(type => type === dataTypeString);

        if (find) {
            return find;
        } else {
            throw new Error('IntegrationStatus not found');
        }
    }

    export function getValues(): string[] {
        return Object.keys(IntegrationStatus)
            .filter(key => typeof IntegrationStatus[key] === 'string')
            .map(key => IntegrationStatus[key]);
    }
}
