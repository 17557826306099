<h3 mat-dialog-title>Legal firm management panel</h3>
<mat-card-content class="status-card">
    <mat-label id="statusLabel">Current legal firm integration status is <strong>{{status}}</strong>. Available options:
    </mat-label>
    <mat-toolbar-row *ngIf="!updateForbidden" class="status-buttons">
        <button (click)="onDeployButtonClick()"
                *ngIf="status === IntegrationStatus.UNDEPLOYED"
                color="primary"
                id="deployButton"
                mat-raised-button style="margin-left: 1%">Deploy
        </button>
        <button (click)="onDeployStartButtonClick()"
                *ngIf="status === IntegrationStatus.UNDEPLOYED"
                color="primary"
                id="deployStartButton"
                mat-raised-button style="margin-left: 1%">Deploy & Start
        </button>
        <button (click)="onDeployStartButtonClick()"
                *ngIf="status === IntegrationStatus.STOPPED"
                class="started"
                id="startButton"
                mat-raised-button style="margin-left: 1%">Start
        </button>
        <button (click)="onDeployButtonClick()"
                *ngIf="status === IntegrationStatus.STARTED"
                class="stopped"
                id="stopButton"
                mat-raised-button style="margin-left: 1%">Stop
        </button>
        <button (click)="onUndeployButtonClick()"
                *ngIf="status !== IntegrationStatus.UNDEPLOYED
                && status !== IntegrationStatus.IN_PROGRESS
                && status !== IntegrationStatus.UPDATE_FAILED"
                class="undeployed"
                id="undeployButton"
                mat-raised-button style="margin-left: 1%">Undeploy
        </button>
        <mat-label *ngIf="status === IntegrationStatus.IN_PROGRESS">No actions are available! Update in
            progress...
        </mat-label>
        <mat-label *ngIf="status === IntegrationStatus.UPDATE_FAILED">Integration is in invalid state. Please contact
            your
            administrator.
        </mat-label>
    </mat-toolbar-row>
    <div *ngIf="updateForbidden" class="updateForbidden"><p><strong>
        Integration system update in progress.<br>
        Please wait for other legal firm integrations update to complete.<br>
        Firm IDs in progress:
    </strong></p>
        <div *ngFor="let id of firmIds | slice:0:10">{{id}}</div>
        <div *ngIf="firmIds.length > 10">...</div>

    </div>

</mat-card-content>
