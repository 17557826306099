import {DisplayIdentifiers} from './display-Identifiers';

export class DisplayData {

    displayIdentifiers: DisplayIdentifiers;
    options: string[];
    order: number;

    constructor() {
    }

}